// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("moment")
require("cocoon-js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import $ from 'jquery'

// JS
import "bootstrap"
import "jquery-ui"
import "dragtable"
import "bootstrap-table"
import "bootstrap-table/dist/locale/bootstrap-table-pl-PL.js"
import "bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns.js"
import "bootstrap-table/dist/extensions/reorder-columns/bootstrap-table-reorder-columns.js"
import "bootstrap-table/dist/extensions/export/bootstrap-table-export.js"
import "tableexport.jquery.plugin"
import "datatables.net"
import "canvasjs/dist/jquery.canvasjs.min.js";
import flatpickr from "flatpickr"
import 'flatpickr/dist/flatpickr.min.css';
import "flatpickr/dist/themes/airbnb.css";
import "flatpickr/dist/l10n/pl.js"
import "./views/component_templates"
import "./front/render_form_errors"
import "./front/component_records"
import "./front/component_records_form"
import "./front/component_records_reordering"
import "./front/tasks"
import "./front/cookies-manager"
import "./front/image_getter"
import "./front/fetching_table_data"
import "./front/table_filters"



// CSS
import 'vue-multiselect/dist/vue-multiselect.min.css';
import "open-iconic/font/css/open-iconic-bootstrap.min.css"
import "bootstrap-table/dist/bootstrap-table.min.css"
import "bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns.css"
import "dragtable/dragtable.css"
import "../stylesheets/application"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';


window.jQuery = $;
window.$ = $;
window.moment = moment;
$.extend($.fn.bootstrapTable.defaults, $.fn.bootstrapTable.locales['pl-PL']);
