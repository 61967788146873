$(function () {
  // TODO: refactor this file
  let bTable = $(".bootstrap-table .fixed-table-body table");
  let dynamicFieldsCount = parseInt(bTable.attr("data-dynamic-fields-count"))
  let storedColumnsOrder = readCookie(bTable.attr("id"))

  initTableReorder = () => {
    if (!storedColumnsOrder) {
      let initialColumnOrder = [...new Set($("th[data-field]").map((i, c) => { return $(c).attr("data-field") }).get())];
      bTable.bootstrapTable('hideAllColumns')
      initialColumnOrder.filter(column => { return !isNaN(parseInt(column)) })
        .forEach((name, i) => { if (i < dynamicFieldsCount) { bTable.bootstrapTable('showColumn', name) } })
      // if column is not an Int show it always
      initialColumnOrder.filter(column => { return isNaN(parseInt(column)) })
        .forEach(col => { bTable.bootstrapTable('showColumn', col) })
      storedColumnsOrder = bTable.bootstrapTable('getVisibleColumns').map(column => { return column.field })
    } else {
      try {
        bTable.bootstrapTable('hideAllColumns')
        storedColumnsOrder.filter(column => { return !isNaN(parseInt(column)) })
          .forEach((name, i) => { if (i < dynamicFieldsCount) { bTable.bootstrapTable('showColumn', name) } })
        // if column is not an Int show it always
        storedColumnsOrder.filter(column => { return isNaN(parseInt(column)) })
          .forEach(col => { bTable.bootstrapTable('showColumn', col) })
      } catch {
        // in case there are some invalid cookies, delete them and reload page
        deleteCookie(bTable.attr("id"));
        location.reload();
      }
    }
  }

  //
  // bootstrap table initial reordering
  //
  initTableReorder()

  //
  // bootstrap table callbacks
  //
  bTable.on("load-success.bs.table", event => {
    // orderColumns should be call after load-success, doesn't work on script init
    // bTable.bootstrapTable('orderColumns', arrayToObject(storedColumnsOrder))
    $('.th-inner:has(> .dropdown-toggle)').css({"padding": 0});
    $('.th-inner .dropdown-toggle').css({"padding": ".75rem"});
  })

  bTable.on("click", ".dropdown-item[data-dd-item]", (event) => {
    let selectedColumnNumber = $(event.currentTarget).closest("th[data-field]").attr("data-field")
    let selectedItem = $(event.currentTarget).attr("data-dd-item")
    let newColumnOrder = []
    // jesli jest visible podmieniaj kolejnosc
    storedColumnsOrder.forEach((col, i) => {
      if (storedColumnsOrder.includes(selectedItem)) {
        if (storedColumnsOrder[i] == selectedItem) {
          newColumnOrder[i] = selectedColumnNumber
        } else if (storedColumnsOrder[i] == selectedColumnNumber) {
          newColumnOrder[i] = selectedItem
        } else {
          newColumnOrder.push(col)
        }
      } else {
        if (storedColumnsOrder[i] == selectedColumnNumber) {
          newColumnOrder[i] = selectedItem
          bTable.bootstrapTable('showColumn', selectedItem)
          bTable.bootstrapTable('hideColumn', selectedColumnNumber)
        } else {
          newColumnOrder.push(col)
        }
      }
    })
    bTable.bootstrapTable('orderColumns', arrayToObject(newColumnOrder))
    saveCookie(bTable.attr("id"), newColumnOrder)
    storedColumnsOrder = newColumnOrder
    location.reload()
  })

  bTable.on("shown.bs.dropdown", "th[data-field]", (e) => {
    e.preventDefault();
    e.stopPropagation();
    const currentTr = $(e.currentTarget);
    const bTable = $(".bootstrap-table .fixed-table-body table");
    const currentColumnName = currentTr.text();
    const hiddenColumns = bTable.bootstrapTable('getHiddenColumns').map((column) => ({ value: column.field, label: $(column.title).text() }))
    const visibleColumns = bTable.bootstrapTable('getVisibleColumns').map(column => ({ value: column.field, label: $(column.title).text() }))
    const allColumns = [...visibleColumns, ...hiddenColumns].filter((column) => !isNaN(parseInt(column.value)));
    const dropdownMenu = currentTr.find(".dropdown-menu")
    const dropdownColumnsItems = allColumns.map((item) => `
      <a href="#" class="dropdown-item pointer" style="text-transform: none;" data-dd-item="${ item.value }">
        ${ item.label }
        ${ item.label === currentColumnName ?
          `<img class="float-right" src="${getImgUrl('checkmark.svg') }"/>`
          : ''
        }
      </a>
    `).join('\n');
    const dropdownMenuHtml = `
      <label class="mb-0 px-3 menu-info">rodzaj kolumny</label>
      <div class="dropdown-submenu">
        <div class="dropdown-item" tabindex="-1">
          ${ currentColumnName }
          <img class="float-right" src="${ getImgUrl("arrow_right.svg") }"/>
        </div>
        <div class="dropdown-menu">
          ${ dropdownColumnsItems }
        </div>
      </div>
      <label class="mb-0 px-3 menu-info">sortuj</label>
      <div class="dropdown-item pointer order-button" data-order="asc">
        <img class="mr-2" src="${ getImgUrl('increasing.svg') }"/>Rosnonco
      </div>
      <div class="dropdown-item pointer order-button" data-order="desc">
        <img class="mr-2" src="${ getImgUrl('decreasing.svg') }"/>Malejąco
      </div>
    `;
    dropdownMenu.html(dropdownMenuHtml);
    $('.order-button').on('click', function(e) {
      const sortName = currentTr.data('field');
      const sortOrder = $(e.target).data('order');
      const params = { sortName, sortOrder, search: false };
      bTable.bootstrapTable('refreshOptions', params)
    })
  })

  bTable.on("hidden.bs.dropdown", "th[data-field]", (e) => {
    let dropdownMenu = $(e.currentTarget).find(".dropdown-menu")
    dropdownMenu.html('');
  })

  bTable.on("reorder-column.bs.table", event => {
    let columnsOrderArray = bTable.bootstrapTable('getVisibleColumns').map(column => { return column.field })
    saveCookie(bTable.attr("id"), columnsOrderArray)
  })
})

arrayToObject = (obj) => {
  return Object.assign({}, ...Object.entries(obj).map(([a, b]) => ({ [b]: parseInt(a) })))
}
